<template>
  <div>
    <vx-card class="px-4 bg-white" >
      <div>
        <div class="flex mb-5 flex-col items-center justify-content-center">
          <div>
            <img width="50" src="../../../assets/images/elements/notification.png" alt="">
          </div>
          <div class="mt-2">
            <h3>لیست اعلانات</h3>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end">
        <vs-button @click="clearNotification" size="small" icon="delete" class="rounded" color="danger" type="flat">حذف اعلانات</vs-button >
      </div>
      <vs-divider />
      <div v-if="notificationList.length >= 1" v-for="(items, index) in notificationList.slice(0,4)" :key="index">
        <router-link class="text-decoration-none" :to="{ path: `/notification/show/${items.id}`, query: { type: 0 } }">
          <div style="background-color: #f8f6f6" class=" text-decoration-none cursor-pointer mt-2 flex justify-between items-center hover-item rounded-lg p-2 w-full">
            <span class="text-xs  ">{{ items.title }} </span>
            <div class="flex items-center">
              <!--                <span :class="[true ? 'badgeAreaAnswered' : 'badgeAreaWaitAnswer']" class="text-xs mr-2 ">در انتظار پاسخ </span>-->
              <b-icon icon="arrow-left" />
            </div>
          </div>
        </router-link>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  name: 'NotificationList',
  data () {
    return {
      Loading: false,
      notificationList: []
    }
  },
  methods : {
    clearNotification () {
      this.$http.post('/notifications/clear').then((res) => {
        if (res.status === 200) {
          this.getUserNotification()
          this.$vs.notify({
            color:'success',
            title: 'اعلانات شما حدف شدند !     '
          })
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getUserNotification () {
      this.Loading = true
      this.$http.get('NotificationsListForNotifBox').then(res => {
        this.notificationList = res.data.params
        console.log('NotificationsListForNotifBox', res.data.params)
        this.Loading = false
      }).catch(err => {
        console.log(err.response.data.message)
      })
    }
  },
  mounted () {
    this.getUserNotification()
  }
}
</script>

<style scoped>
.blue-light {
  background-color: #BFDBFE !important;
  border-radius: 1rem;
  filter: drop-shadow(5px 5px 10px #BFDBFE);
}
.bg-card {
  box-shadow: none !important;
  background-color: #e9ebec !important;
}
.hover-item:hover {
  transition: 0.5s;
  background-color: #E5E7EB !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}
.badgeAreaWaitAnswer {
  background-color: #fa9276;
  padding: 2px;
  color: white;
  border-radius: 2rem;
  font-size: 8px !important;
}
.badgeAreaAnswered {
  background-color: #6EE7B7;
  padding: 2px;
  color: white;
  border-radius: 2rem;
  font-size: 8px !important;
}
</style>
